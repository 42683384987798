import React from 'react';
import { read, utils } from 'xlsx';

interface ComponentProps {
  encoding?: string,
  buttonSize?: "tiny" | "normal" | "big",
  excel?: boolean,
  onFileUpload(file: string | Array<{}>) : any,
}

type FileUploaderProps = ComponentProps;

const FileUploader: React.FC<FileUploaderProps> = ({
  encoding,
  buttonSize,
  excel,
  onFileUpload,
}) => {

  const readFile = (file: File) => {
    if (!file) return;
    if (excel && !file.name.includes(".xls")) {
      alert('Falsche Datei ausgewählt! Es wird eine Excel Datei erwartet.');
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target) return;
      if(excel) {
        //Excel file import
        const wb =  read(e.target.result, {type:'binary'});
        const ws = wb.Sheets[wb.SheetNames[0]];
        onFileUpload(utils.sheet_to_json(ws) as Array<{}>);
      } else {
        //CSV import
        onFileUpload(e.target.result as string);
      }
    }

    if (excel) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsText(file, encoding);
    }
  };

  let buttonClass = "button button--file";
  buttonClass = buttonClass.concat(buttonSize === "big" ? " button--big" : buttonSize === "tiny" ? " button--tiny" : "");

  return (
    <div className={buttonClass}>
      <label>
        <input 
          type="file"
          onChange={e => e.target.files ? readFile(e.target.files[0]) : null}
          onClick={(e) => (e.target as HTMLInputElement).value = ""}
        />
        Datei importieren
      </label>
    </div>
  )
}

export default FileUploader;