export interface InvoiceInterface {
    type: "invoice" | "correction" //Correction will create invoice with negative pricing
    language: "de" | "en",
    address: string,
    deliveryAddress: string,
    invoiceNumber: string,
    customerId: string,
    date: string, //If not provided it will be set automatically
    orderNumber: string,
    orderDate: string,
    deliveryDate: string, //If not provided it will be set automatically
    articles: Article[],
    VAT: number, //In percentage
    footer: {
        paymentMethod: "payPal" | "creditCard" | "bankTransfer",
        shippingMethod: "parcel"
    },
    priceType: "net" | "gross",
    country: string,

    customTitle: string ,
    customFooter: string 
}

export interface Address {
    name: string,
    street: string, 
    city: string,
    postcode: string,
    country: string,
}

export interface Article {
    articleNumber?: string,
    description?: string,
    quantity?: number,
    unit?: string,
    germanPrice?: number,
    price?: number,
    netPrice?: number,
}

export const DefaultInvoiceData: InvoiceInterface = {
    type: "invoice",
    language: "de",
    address: "",
    deliveryAddress: "",
    invoiceNumber: "",
    customerId: "",
    date: new Date().toLocaleDateString(),
    orderNumber: "",
    orderDate: new Date().toLocaleDateString(),
    deliveryDate: new Date().toLocaleDateString(),
    articles: [],
    VAT: 19,
    footer: {
        shippingMethod: "parcel",
        paymentMethod: "payPal"
    },
    priceType: "gross",
    country: "DE",

    customTitle: "",
    customFooter: "" 
}