import React from 'react';
import './App.scss';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import InvoiceGenerator from './pages/InvoiceGenerator';
import { Route, Routes, useNavigate } from 'react-router';
import Start from './pages/Start';
import { BrowserRouter } from 'react-router-dom';
import Page404 from './pages/Page404';
import { Hammer } from './icons/IonIcons';
import WaWiToAgenda from './pages/WaWiToAgenda';
import NovalnetToAgenda from './pages/NovalnetToAgenda';
import TimeTracking from './pages/TimeTracking';
import RequireLogin from './components/RequireLogin';
import VatRates from './pages/VatRates';
import ShopifyToAgenda from './pages/ShopifyToAgenda';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Headder />
        <div className="App__body">
        <Routes>
          <Route path="/" element={<Start />} />
          <Route path="/invoice-generator" element={<InvoiceGenerator />} />
          <Route path="/wawi-to-agenda" element={<WaWiToAgenda />} />
          <Route path="/novalnet-to-agenda" element={<NovalnetToAgenda />} />
          <Route path="/shopify-to-agenda" element={<ShopifyToAgenda />} />
          <Route path="/time-tracking" element={
            <RequireLogin component={TimeTracking} /> //This is not secure!
            } />
          <Route path="/vat-rates" element={<VatRates />} />
          <Route path ="*" element={<Page404 />} />
        </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;


const Headder = () => {
  const navigate = useNavigate();
  return (
    <div className="App__headder" onClick={() => navigate("/")}>
      <img src={"icons/IclettaLogo.svg"} alt="ICLETTA Logo"/>
      <Hammer />
      <h1>Toolbox</h1>
    </div>
  )
}
