import React, { useState } from 'react';
import { useEffect } from 'react';
import FileUploader from '../components/FileUploader';
import { AgendaExportHead } from '../types/agenda';
import '../App.scss'

interface expectedImport {
  Beleg1: string,
  Buchungstext: string,
  Datum: string,
  Gegenkonto: string,
  Konto: string | number,
  ["Umsatz in Euro"]: number,
  ["Umsatzsteuer-ID"]: string,
}

const WaWiToAgenda: React.FC = () => {

  const [vatRates, setVatRates] = useState();

  useEffect(() => {
  //get VAT rates
  fetch('configs/vatRates.json').then(res => {
    res.json().then(data => {
      setVatRates(data);
    });
  })
  },[]);

  const convertData = (file: Array<expectedImport>) => {
    if (!vatRates) {
      alert("Es ist ein Fehler aufgetreten. Lade die Seite neu und versuche es noch einmal!");
      return;
    }
    //Convert data
    const newExport:Array<any> = file.map(line => {
      const newLine: any = {...line};
      const date = new Date(Math.round((parseInt(line.Datum) - 25569)*86400*1000));
      newLine.Datum = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
      newLine.Gegenkonto = line.Konto;
      newLine.Konto = line.Gegenkonto;
      newLine['Umsatz in Euro'] = -line['Umsatz in Euro'];
      newLine.Steuerschl = 0;
      //detect old and new Konto and use "==" to compare numbers and strings  - disable eslint because of ==
      //eslint-disable-next-line
      if (line.Konto == 8320 || line.Konto == 8315) {
        const countryCode = line.Buchungstext.substr(line.Buchungstext.length-2, 2);
        if (vatRates![countryCode]) {
          //EU country with changed VAT
          newLine.VAT = (vatRates![countryCode] as any ).rate;
          newLine.countryCode = countryCode;
          newLine.Ursprung = "DE";
          newLine.Steuerschl = 10;
          newLine.Gegenkonto = 8320; //change 8315 to 8320 because of agenda logic
        } else {
          alert('Es wurde das Konto 8320 erkannt, aber es konnte kein passendes EU-Land identifiziert und somit keine Umsatzsteueranpassung vorgenommen werden. Sprechen Sie mit Ihrem Admin!');
        }
      }
      //Remove zero lines
      if (line['Umsatz in Euro'] === 0) {
        return null;
      } else {
        return newLine;
      }
    });
   
    
    //Export data
    let exportCSV = "\"" + AgendaExportHead.join("\";\"").concat("\"\n");
    newExport.forEach(line => {
      exportCSV = exportCSV.concat(`"${line['Umsatz in Euro'].toString().replace(".",",")}";`);
      exportCSV = exportCSV.concat(`"${line.Steuerschl}";`);
      exportCSV = exportCSV.concat(`"${line.Gegenkonto}";`);
      exportCSV = exportCSV.concat(`"${line.Beleg1}";`);
      exportCSV = exportCSV.concat(`"${line.Datum}";`);
      exportCSV = exportCSV.concat(`"${line.Konto}";`);
      exportCSV = exportCSV.concat(`"${line.Buchungstext}";`);
      exportCSV = exportCSV.concat(`"${line['Umsatzsteuer-ID']}";`);
      exportCSV = exportCSV.concat(`"${line.Ursprung ? line.Ursprung : ""}";`);
      exportCSV = exportCSV.concat(`"${line.countryCode ? line.countryCode : ""}";`);
      exportCSV = exportCSV.concat(`"${line.VAT ? line.VAT : 0}"\n`);
    });
    
    const filename = "AgendaImport.csv"
    
    const download = document.createElement('a');
    download.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(exportCSV));
    download.setAttribute('download', filename);
    
    download.click();
    
  };


  return(
  <div className = "page page--center">
    <div>
      <h2>Hier den WaWi-Export hochladen!</h2> 
    </div>
    <FileUploader
      onFileUpload={convertData}
      buttonSize="big"
      excel />
  </div>
  )
}

export default WaWiToAgenda;