import React, { useState } from 'react';
import { useEffect } from 'react';
import '../App.scss'

interface ApiVatRatesInterface {
  countryCode: String,
  countryName: String,
  rate: number
}

const VatRates: React.FC = () => {

  const [savedVatRates, setSavedVatRates] = useState({});
  const [apiVatRates, setApiVatRates] = useState<Array<ApiVatRatesInterface>>([]);
  const [missingCountries, setMissingCountries] = useState<Array<any>>([]);

  useEffect(() => {
    //api-proxy is setup in docker-nginx -- nginx/nginx.conf
    fetch('api-proxy/countrylist/').then(async response => {
        const countryList  = await response.json();
        let apiRates: ApiVatRatesInterface[] = [];
        (countryList as Array<any>).forEach(country => {
         fetch('api-proxy/rates/' + country.code).then(async rateResponse => {
            const rateData = await rateResponse.json();
            //TODO For now array is hardcoded because of bugs in API (Twice Standard rate with wrong values) It seems rates[2] is always the correct value
            apiRates.push({countryCode: (country.code as String), rate: rateData.rates[2].rates[0], countryName: country.name});
            setApiVatRates([...apiRates]);
          })
        })
      })
    .catch(error => console.error(error));

    fetch('configs/vatRates.json').then(res => {
      res.json().then(data => {
        setSavedVatRates(data);
      });
    })
  }, []);

  useEffect(() => {
    const missingRates = Object.entries(savedVatRates).filter(([savedKey, savedValue]) => 
      !apiVatRates.find(rate => rate.countryCode === savedKey)
    )
    const newMissingCountries = missingRates.map(rate => {return {code: (rate as any)[0] as string, name: (rate as any)[1].countryName as string, rate: (rate as any)[1].rate as number}});
    setMissingCountries(newMissingCountries);
  }, [savedVatRates, apiVatRates])


  return(
  <div className = "page page--center">
    <div>
      <h2>Steuersätze EU-Länder</h2> 
    </div>
    <table>
      <thead>
        <tr>
          <td>Ländercode</td>
          <td>Name</td>
          <td>Standard Steuersatz</td>
        </tr>
      </thead>
      <tbody>
        {apiVatRates.map(rate => {
          return (
            <tr key={"table" + rate.countryCode} style={{color: ((savedVatRates as any)[rate.countryCode as any]?.rate === rate.rate ? "inherit" : 'red') }}>
              <td>{rate.countryCode}</td>
              <td>{(savedVatRates as any)[rate.countryCode as any]?.countryName ?? rate.countryName}</td>
              <td>{rate.rate}</td>
            </tr>
          )
        })}
        <tr><td><br/></td></tr>
        {missingCountries.map(country => 
          <tr key={"missing:"+ country.code} style={{color: "orange"}}>
            <td>{country.code}</td>
            <td>{country.name}</td>
            <td>{country.rate + " Keine aktuellen Infos erhalten"}</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
  )
}

export default VatRates;