import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tax } from '../icons/Icons';
import { DocumentIcon, Stopwatch } from '../icons/IonIcons';
import './css/Start.scss';

const Start: React.FC = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="start__headder">PDF's erzeugen</div>
      <div className="start__category">
        <div className="start__category__tile" onClick={() => navigate("/invoice-generator")}>
          <DocumentIcon />
          <div> Rechnung erzeugen </div>
        </div>
      </div>
      <div className="start__headder">Agenda Import vorbereiten</div>
      <div className="start__category">
        <div className="start__category__tile" onClick={() => navigate("/wawi-to-agenda")}>
          <img src="/icons/IclettaSingleLogo.svg" alt="icletta logo" />
          <div> WaWi </div>
        </div>
        <div className="start__category__tile" onClick={() => navigate("/novalnet-to-agenda")}>
          <img src="/icons/novalnet.png" alt="novalnet" />
          <div> Novalnet </div>
        </div>
        <div className="start__category__tile" onClick={() => navigate("/shopify-to-agenda")}>
          <img src="/icons/shopify-icon.svg" alt="novalnet" />
          <div> Shopify </div>
        </div>
      </div>
      <div className="start__headder">Andere Tools</div>
      <div className="start__category">
        <div className="start__category__tile" onClick={() => navigate("/time-tracking")}>
          <Stopwatch />
          <div> Zeiterfassung </div>
        </div>
        <div className="start__category__tile" onClick={() => navigate("/vat-rates")}>
          <Tax />
          <div> Steuersätze </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Start;