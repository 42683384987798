import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const Login: React.FC = () => {
  const [login, setLogin] = useState("");

  const saveLogin = () => {
    sessionStorage.setItem("login", login);
    setLogin("");
  }

  return (
    <React.Fragment>
      <h1>Login:</h1>
      <form onSubmit={saveLogin}>
        <input type="password" value={login} onChange={(e) => setLogin(e.target.value)} />
        <br />
        <div className="button button--inline" onClick={saveLogin}>
          Absenden
        </div>
      </form>
      <div><Link to="/">Zurück zum Start!</Link></div>
    </React.Fragment>
  )
}

export default Login;