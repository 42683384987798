import React, { useState } from 'react';
import FileUploader from '../components/FileUploader';
import '../App.scss'
import './css/TimeTracking.scss'
import {getHolidaysOfMonth, getISO8601_week_no, getWorkingDaysOfMonth} from '../util/dateService'
import {TimeTrackingDefaultSettings, TimeTrackingExportFileHead, TimeTrackingRules} from '../types/timeTracking'
import { Watch } from  'react-loader-spinner'


interface expectedImport {
  startDate: string,
  endDate: string,
  startTime: string,
  endTime: string,
  wageRate: string,
  note: string,
}

interface exportHelper extends expectedImport {
  week?: number,
  workingTime?: number,
}

interface exportData {
  totalMinutes: number,
  month: number,
  year: number,
  timeTable: expectedImport[],
}

interface detectedDataInterface extends exportData {
  transferTable: expectedImport[],
}

const EmptyExportData: exportData = {
  totalMinutes: 0,
  month: 0,
  year: 0,
  timeTable: [],
}

const EmptyDetectedData: detectedDataInterface = {...EmptyExportData,
  transferTable: [],
}


const TimeTracking: React.FC = () => {

  const [settings, setSettings] = useState(TimeTrackingDefaultSettings);
  const [detectedData, setDetectedData] = useState(EmptyDetectedData);
  const [exportedData, setExportedData] = useState(EmptyExportData);
  const [isGenerating, setIsGenerating] = useState(false);

  const convertData = (file: string) => {
    const lines = file.split("\n");
    const importData:Array<expectedImport> = [];
    const transferData:Array<expectedImport> = [];

    //Check file
    if(lines[0].split(";")[0] !== "DATUM_VON") {
      alert("Unerwartete Datei, oder mit der Datei stimmt etwas nicht!");
      return;
    }
    //Remove heading
    lines.splice(0,1);

    let abortImport = false;
    lines.forEach(line => {
      const newLine = line.split(";");
        let lineData = {
          startDate: newLine[0],
          endDate: newLine[1],
          startTime: newLine[2],
          endTime: newLine[3],
          wageRate: newLine[4],
          note: newLine[5]
        }
        if (newLine.length >= 6) {
          importData.push(lineData);
          //Transfer all data that is not 
          if (lineData.note.trim() !== "Arbeitszeit") {
            transferData.push(lineData);
          }
          // Just a sanity check
          if (lineData.startDate !== lineData.endDate) {
            alert('Es wurde eine Arbeitszeit erkannt, die sich über mehrere Tage erstreckt. Dieses Tool unterstützt keine tagesübergreifende Arbeitszeiten!')
            abortImport = true
          }
        }
    })
    if (abortImport) return;

    //Get Year and Month of import
    const year = importData[0].startDate.split(".")[2]; //As String YYYY
    const month = importData[0].startDate.split(".")[1]; //As String MM

    //Calculate total working time in minutes
    let minutes = 0;
    importData.forEach(data => {
      //minutes
      minutes += parseInt(data.endTime.split(":")[1]) - parseInt(data.startTime.split(":")[1]);
      //hours
      minutes += (parseInt(data.endTime.split(":")[0]) - parseInt(data.startTime.split(":")[0])) * 60;
    })
    setDetectedData({year: parseInt(year), month: parseInt(month), totalMinutes: minutes, timeTable: importData, transferTable: transferData});

    //Generate new export
    setIsGenerating(true);
    generateExport({providedData:{year: parseInt(year), month: parseInt(month), totalMinutes: minutes, timeTable: importData, transferTable: transferData}});
  };

  const generateExport = (props?: {providedData?:detectedDataInterface, abortCounter?: number}) => {
    let data = props? props.providedData ? props.providedData : detectedData : detectedData;
    if (!data.year) return;
    let selectedDays:{date: string, week: number}[] = [];
    let availableDays = getWorkingDaysOfMonth(data.year, data.month-1, settings.requestedDays);
    let requiredDays = Math.round(settings.monthlyTarget*60/TimeTrackingRules.workingTime.daily.average);

    //Create export
    let exportData: exportHelper[] = [];
    let totalMinutesThisMonth = 0;
    
    //Add forced dates to selected dates
    settings.mandatoryDates?.forEach(date => {
      if (date) { //Filter possible empty array elements  
        selectedDays.push({
          date: date.padStart(2, "0"),
          week: getISO8601_week_no(new Date(Date.UTC(data.year, data.month-1, parseInt(date))))
        })
      }
    })

    //Write sick days and holiday dates to export
    settings.holidayDates?.forEach(date => {
      if (date) { //Filter possible empty array elements  
        exportData.push({
          startTime: "00:00",
          endTime: Math.trunc(settings.dailyTarget).toString().padStart(2, "0") + ":" + Math.round((settings.dailyTarget*60) % 60).toString().padStart(2, "0"),
          startDate: date.padStart(2, "0") + "." + detectedData.month.toString().padStart(2, "0") + "." + detectedData.year,
          endDate: date.padStart(2, "0") + "." + detectedData.month.toString().padStart(2, "0") + "." + detectedData.year,
          note: "Urlaub",
          wageRate: ""
        })
      }
    });
    settings.sickDates?.forEach(date => {
      if (date) { //Filter possible empty array elements  
        exportData.push({
          startTime: "00:00",
          endTime: Math.trunc(settings.dailyTarget).toString().padStart(2, "0") + ":" + Math.round((settings.dailyTarget*60) % 60).toString().padStart(2, "0"),
          startDate: date.padStart(2, "0") + "." + detectedData.month.toString().padStart(2, "0") + "." + detectedData.year,
          endDate: date.padStart(2, "0") + "." + detectedData.month.toString().padStart(2, "0") + "." + detectedData.year,
          note: "Krank",
          wageRate: ""
        })
      }
    });
    //Write all transfer data
    data.transferTable.forEach(line => {
      exportData.push({...line,
        endTime: line.endTime.substring(0,5),
        startTime: line.startTime.substring(0,5),
      });
    })
    //Count minutes of transferData, holiday and sick days as working time
    exportData.forEach(data => {
      //minutes
      totalMinutesThisMonth += parseInt(data.endTime.split(":")[1]) - parseInt(data.startTime.split(":")[1]);
      //hours
      totalMinutesThisMonth += (parseInt(data.endTime.split(":")[0]) - parseInt(data.startTime.split(":")[0])) * 60;
    })
    
    //Remove forced dates, transferDates, sick dates and holiday dates from available dates
    availableDays = availableDays.filter(avDate => filteravailableDates(avDate.date, selectedDays, data));
    console.log(availableDays);

    //Get working days
    if (settings.isMinijob) {
      //Generate random working days
      while ((selectedDays.length + data.transferTable.length) < requiredDays) {
        if (availableDays.length === 0) {
          alert('Es sind nicht genügend Arbeitstage vorhanden, um den Soll zu erfüllen. Ändere die Einstellungen!');
          break;
        }
        let randomIndex = Math.round(Math.random()*(availableDays.length-1));
        selectedDays.push(availableDays.splice(randomIndex, 1)[0]);
      }
    } else {
      //Get every workingday.
      selectedDays = selectedDays.concat(availableDays);
    }
    
    const holidays = getHolidaysOfMonth(data.year, data.month-1);
    if (!settings.isMinijob) {
      //Get holidays and create holiday entrys
      holidays.forEach(date => {
        exportData.push({
          startDate: (date.date.padStart(2, "0") + "." + data.month.toString().padStart(2, "0") + "." + data.year),
          endDate: (date.date.padStart(2, "0") + "." + data.month.toString().padStart(2, "0") + "." + data.year),
          startTime: "00:00",
          endTime: (Math.trunc(settings.dailyTarget).toString().padStart(2, "0") + ":" + (Math.round((settings.dailyTarget*60)) % 60).toString().padStart(2, "0")),
          note: "Feiertag: " + date.name,
          wageRate: "",
        });
      });
      //Count minutes of transferData as working time
      totalMinutesThisMonth += holidays.length * Math.round(settings.dailyTarget * 60);
    }

    //Generate data
    selectedDays.forEach((day, index) => {
      const startEndDate = `${day.date}.${data.month.toString().padStart(2, "0")}.${data.year}`;
      let startTimeHour = TimeTrackingRules.startOfWork.averageTime.hour;
      let startTimeMinute = TimeTrackingRules.startOfWork.averageTime.minute + Math.round((Math.random()*2-1)*TimeTrackingRules.startOfWork.maxVariationOfTime);
      while (startTimeMinute < 0) {
        startTimeHour --;
        startTimeMinute += 60;
      }
      let dailyTarget = settings.isMinijob ? TimeTrackingRules.workingTime.daily.average : Math.round(settings.dailyTarget*60);
      const dailyVariation = settings.isMinijob ? TimeTrackingRules.workingTime.daily.maxVariation : TimeTrackingRules.workingTime.daily.maxVariationNoMinijob;

      let totalWorkingTimeThisDay = dailyTarget + Math.round((Math.random()*2-1)*dailyVariation);

      if (settings.forceTarget) {
        //Reduce variation of all days and aim for target
        dailyTarget = settings.isMinijob ? Math.round((settings.monthlyTarget*60-totalMinutesThisMonth)/(selectedDays.length - index)) :  Math.round(((settings.dailyTarget * 60 * (selectedDays.length + data.transferTable.length + holidays.length)) - totalMinutesThisMonth)/(selectedDays.length - index));
        totalWorkingTimeThisDay = dailyTarget + Math.round((Math.random()-0.5)*dailyVariation);
        if (index === (selectedDays.length-1)) {
          //Last day of month will hit the target
          totalWorkingTimeThisDay = settings.isMinijob ? (Math.round(settings.monthlyTarget * 60) - totalMinutesThisMonth) : (settings.dailyTarget * 60 * (exportData.length + 1) - totalMinutesThisMonth);
        }
      }

      const breakRequired = totalWorkingTimeThisDay >= TimeTrackingRules.break.workingTimeRequiresBreak ? true : false;
      const breakTime = breakRequired ? (TimeTrackingRules.break.minBreakTime + Math.round(Math.random()*TimeTrackingRules.break.maxBreakTimeVariation)) : 0;
     
      totalMinutesThisMonth += totalWorkingTimeThisDay;

      let endTimeMinute = startTimeMinute + totalWorkingTimeThisDay;
      let endTimeHour = startTimeHour;
      while (endTimeMinute >= 60) {
        endTimeMinute -= 60;
        endTimeHour++;
      }

      if (breakRequired) {
        let breakStartHour = startTimeHour;
        let breakStartMinute = startTimeMinute + TimeTrackingRules.break.minutesUntilBreakStart;
        while (breakStartMinute >= 60) {
          breakStartMinute -= 60;
          breakStartHour++;
        }
        let breakEndHour = breakStartHour;
        let breakEndMinute = breakStartMinute + breakTime;
        while (breakEndMinute >= 60) {
          breakEndMinute -= 60;
          breakEndHour++;
        }

        exportData = exportData.concat([{
          startDate: startEndDate,
          endDate: startEndDate,
          startTime: `${startTimeHour.toString().padStart(2, "0")}:${startTimeMinute.toString().padStart(2, "0")}`,
          endTime: `${breakStartHour.toString().padStart(2, "0")}:${breakStartMinute.toString().padStart(2, "0")}`,
          wageRate: "",
          note: "Arbeitszeit",
          workingTime: totalWorkingTimeThisDay,
          week: day.week
        },{
          startDate: startEndDate,
          endDate: startEndDate,
          startTime: `${breakEndHour.toString().padStart(2, "0")}:${breakEndMinute.toString().padStart(2, "0")}`,
          endTime: `${endTimeHour.toString().padStart(2, "0")}:${endTimeMinute.toString().padStart(2, "0")}`,
          wageRate: "",
          note: "Arbeitszeit",
          workingTime: totalWorkingTimeThisDay,
          week: day.week
        }]);

      } else {
        exportData.push({
          startDate: startEndDate,
          endDate: startEndDate,
          startTime: `${startTimeHour.toString().padStart(2, "0")}:${startTimeMinute.toString().padStart(2, "0")}`,
          endTime: `${endTimeHour.toString().padStart(2, "0")}:${endTimeMinute.toString().padStart(2, "0")}`,
          wageRate: "",
          note: "Arbeitszeit",
          workingTime: totalWorkingTimeThisDay,
          week: day.week
        })
      }

    })
    exportData.sort((a,b) => parseInt(a.startDate) - parseInt(b.startDate) || parseInt(a.startTime) - parseInt(b.startTime));

    if (isRuleViolated(exportData)) {
      if (props?.abortCounter && props.abortCounter > 100) {
        alert("Es konnte kein valider Export mit den vorhandenen Einstellungen erstellt werden. Ändere die Einstellungen, versuche es erneut oder korrigiere die Daten händisch.");
        setIsGenerating(false);
        return;
      }
      //Rule is violated - try another time with random working days
      const newProps = {
        abortCounter: props? props.abortCounter? props.abortCounter+1 : 1 : 1,
        providedData: props?.providedData
      }
      setTimeout(() => {
        generateExport(newProps)
      }, 0)
    } else {
      //Finished generating data
      setExportedData({
        year: data.year,
        month: data.month,
        totalMinutes: totalMinutesThisMonth,
        timeTable: exportData
      });
      setIsGenerating(false);
    }
  }

  const isRuleViolated = (data: exportHelper[]) => {
    let ruleViolation = false;
    let currentWeek = 0
    let weekWorkingTime = 0

    data.forEach(entry => {
      //Only check violations when it's wokring time
      if (entry.note.trim() === "Arbeitszeit") {
        //Check if there are more than 20 hours a week when minijobber
        if (settings.isMinijob) {
          if (entry.week !== currentWeek) {
            currentWeek = entry.week!
            weekWorkingTime = entry.workingTime!;
          } else {
            weekWorkingTime += entry.workingTime!
          }
          if (weekWorkingTime > TimeTrackingRules.workingTime.perWeek.max) {
            ruleViolation = true;
          }
          //Check if one day has too much or too less hours (can happen when user forces target)
          let variation = Math.abs(TimeTrackingRules.workingTime.daily.average - entry.workingTime!)
          if (variation > TimeTrackingRules.workingTime.daily.maxVariation) {
            ruleViolation = true;
          }
        }
      }
    });

    //TODO ?? Check if working monthly variation is bigger than 10% ?! Do we even care?
    return ruleViolation
  }

  const filteravailableDates = (date: string, selectedDates: any[],  data: detectedDataInterface) => {
    if (selectedDates.find(selDay => selDay.date === date)) {
      return false;
    }
    if (data.transferTable.find(entry => entry.startDate.split(".")[0] === date)) {
      return false;
    }
    if (settings.holidayDates?.find(hDate => hDate.padStart(2, "0") === date)) {
      return false;
    }
    if (settings.sickDates?.find(sDate => sDate.padStart(2, "0") === date)) {
      return false;
    }
    return true;
  }

  const handleRequestedDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let requestedDays = settings.requestedDays ? settings.requestedDays : [];
    if (e.target.checked) {
      requestedDays.push(parseInt(e.target.id));
    } else {
      requestedDays = requestedDays.filter(day => day !== parseInt(e.target.id))
    }

    setSettings(oldData => {return {...oldData, requestedDays: requestedDays}})
  }

  const handleMandatoryDatesChange = (e: React.ChangeEvent<HTMLInputElement>)  => {
    let string = e.target.value.slice();
    //Catch some user errors
    string = string.replace(" ", "");
    string = string.replace(".", ",");
    setSettings(oldData => {return {...oldData, mandatoryDates: string.split(",")}})
  }

  const handleSickDatesChange = (e: React.ChangeEvent<HTMLInputElement>)  => {
    let string = e.target.value.slice();
    //Catch some user errors
    string = string.replace(" ", "");
    string = string.replace(".", ",");
    setSettings(oldData => {return {...oldData, sickDates: string.split(",")}})
  }

  const handleholidayDatesChange = (e: React.ChangeEvent<HTMLInputElement>)  => {
    let string = e.target.value.slice();
    //Catch some user errors
    string = string.replace(" ", "");
    string = string.replace(".", ",");
    setSettings(oldData => {return {...oldData, holidayDates: string.split(",")}})
  }

  const downloadExportData = () => {
    let exportTXT = TimeTrackingExportFileHead.join(";").concat("\n");

    exportedData.timeTable.forEach(entry => {
      exportTXT = exportTXT.concat(`${entry.startDate};${entry.endDate};${entry.startTime} Uhr;${entry.endTime} Uhr;${entry.wageRate};${entry.note}\n`);
    })

    const filename = "Korrigierte Zeiterfassung.txt"
    
    const download = document.createElement('a');
    download.setAttribute('href', 'data:text/txt;charset=utf-8,' + encodeURIComponent(exportTXT));
    download.setAttribute('download', filename);
    
    download.click();
  }

  const onTargetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSettings(oldData => { return {...oldData,
      monthlyTarget: oldData.isMinijob ? e.target.valueAsNumber?? oldData.monthlyTarget : oldData.monthlyTarget,
      dailyTarget: oldData.isMinijob ? oldData.dailyTarget : e.target.valueAsNumber?? oldData.dailyTarget
    }})
  }


  return (
  <React.Fragment>
  <div className = "page">
    <div>
      <h2>Zeiterfassung-Export hier hochladen:</h2> 
    </div>
    <FileUploader onFileUpload={convertData} />
    <div className="page__seperator" />
    <div className="page__row">
      <div className="page__column">
        <h3>Arbeitszeit konfigurieren</h3>
        <div className="page__seperator" />
        <div className="page--big-text">
          <div>Minijobber</div>
          <input type = "checkbox" checked={settings.isMinijob} onChange={e => setSettings(oldData => {return {...oldData, isMinijob: e.target.checked}})} />
          <div>Soll</div>
          <input type="number" value={settings.isMinijob ? settings.monthlyTarget : settings.dailyTarget} onChange={onTargetChange} />
          <div>Soll erzwingen</div>
          <input type="checkbox" checked={settings.forceTarget} onChange={e => setSettings(oldData => { return { ...oldData, forceTarget: e.target.checked}})} />
          <div className="page__seperator" />
          {settings.isMinijob ?
            <React.Fragment>
            <div>Wochentage beschränken</div>
            <table className = "days-table">
              <tbody>
                <tr>
                  <td>Mo</td><td>Di</td><td>Mi</td><td>Do</td><td>Fr</td>
                </tr>
                <tr>
                  <td><input id="1" type="checkbox" checked={settings.requestedDays ? settings.requestedDays.find(day => day === 1) ? true : false : false} onChange={handleRequestedDaysChange} /></td>
                  <td><input id="2" type="checkbox" checked={settings.requestedDays ? settings.requestedDays.find(day => day === 2) ? true : false : false} onChange={handleRequestedDaysChange} /></td>
                  <td><input id="3" type="checkbox" checked={settings.requestedDays ? settings.requestedDays.find(day => day === 3) ? true : false : false} onChange={handleRequestedDaysChange} /></td>
                  <td><input id="4" type="checkbox" checked={settings.requestedDays ? settings.requestedDays.find(day => day === 4) ? true : false : false} onChange={handleRequestedDaysChange} /></td>
                  <td><input id="5" type="checkbox" checked={settings.requestedDays ? settings.requestedDays.find(day => day === 5) ? true : false : false} onChange={handleRequestedDaysChange} /></td>
                </tr>
              </tbody>
            </table>
            <div className="page__seperator" />
            <div>Tage erzwingen</div>
            <input type="text" value={settings.mandatoryDates? settings.mandatoryDates.join(",") : ""} onChange={handleMandatoryDatesChange} />
            </React.Fragment>
            :
            <React.Fragment>
              <div>Krankheitstage</div>
              <input type="text" value={settings.sickDates? settings.sickDates?.join(",") : ""} onChange={handleSickDatesChange} />
              <br />
              <div>Urlaubstage</div>
              <input type="text" value={settings.holidayDates? settings.holidayDates.join(",") : ""} onChange={handleholidayDatesChange} />
            </React.Fragment>
          }
          <div className='page--small-text' >
            Mehrere Tage werden mit Komma getrennt<br />
            Beispiel: 10,11,12
          </div>
          <div className="page__seperator" />
          <label className="button page--small-text" onClick={() => {setIsGenerating(true); generateExport()}} >Erneut generieren </label>
        </div>
      </div>
      <div className='page__column' >
        <h3>Erkannte Daten</h3>
        <div className="page__seperator" />
        <div className="page--big-text">
          <div>Arbeitszeit</div>
          <div className="preview-data">{Math.trunc(detectedData.totalMinutes/60) + ":" + (detectedData.totalMinutes % 60).toString().padStart(2,"0")}</div>
          <div>Monat</div>
          <input type="number" value={detectedData.month} onChange={(e) => setDetectedData((oldaData) => {return {...oldaData, month: e.target.valueAsNumber}})} />
          <div>Jahr</div>
          <input type="number" value={detectedData.year} onChange={(e) => setDetectedData((oldaData) => {return {...oldaData, year: e.target.valueAsNumber}})} />
        </div>
      </div>
      <div className='page__column' >
        <h3>Generierte Daten</h3>
        <div className="page__seperator" />
        <div className="page--big-text">
          <div>Arbeitszeit</div>
          <div className="preview-data">{Math.trunc(exportedData.totalMinutes/60) + ":" + (exportedData.totalMinutes % 60).toString().padStart(2,"0")}</div>
        </div>
        <div className="page__seperator" />
        <table className='preview-table'>
          <tbody>
            <tr>
              <td>Datum</td><td>Start</td><td>Ende</td><td>Notiz</td>
            </tr>
            {
              exportedData.timeTable.map((time, index) => 
                <tr key={"preview"+index}><td>{time.startDate}</td><td>{time.startTime}</td><td>{time.endTime}</td><td>{time.note}</td></tr>
              )
            }
          </tbody>
        </table>
        <div className="page__seperator" />
        <label className="button page--small-text" onClick={() => downloadExportData()} >Daten herunterladen</label>
      </div>
    </div>
  </div>
  {isGenerating ?
    <div className="backdrop">
      <Watch
        height="10rem"
        width="10rem"
        color="#ff5500"
        />
    </div> : null
    }
  </React.Fragment>
  )
}

export default TimeTracking;