import React from 'react';
import { Link } from 'react-router-dom';

const Page404: React.FC = () => {
  return (
    <React.Fragment>
      <h1>Wir kaufen nix!</h1>
      <div><Link to="/">Zurück zum Start!</Link></div>
      <div style={{marginTop: "20px", fontSize: "0.8rem"}}>Error 404</div>
    </React.Fragment>
  )
}

export default Page404;