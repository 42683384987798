import React from 'react';
import FileUploader from '../components/FileUploader';
import { AgendaExportHead } from '../types/agenda';
import '../App.scss'


const NovalnetToAgenda: React.FC = () => {

  const convertData = (file: string) => {
    const lines = file.split("\n");

    const newExport:Array<any> = [];
    if(lines[0].split(";")[0] !== "Betrag") {
      alert("Unerwartete Datei, oder mit der Datei stimmt etwas nicht!");
      return;
    }
    lines.forEach(line => {
      const newLine = line.split(";");
        let lineData = {
          Betrag: -parseFloat(newLine[0].replace(",",".")),
          Datum: newLine[1],
          Zahlungsart: newLine[2],
          Nachname: newLine[3]
        }

      if (lineData.Zahlungsart === "PAYPAL" || !lineData.Betrag || lineData.Zahlungsart === "" || isNaN(lineData.Betrag) ) {
      } else {
        newExport.push(lineData);
      }
    })

    //Export data
    const Gegenkonto = 1270;
    const Konto = 10000;
    
    let exportCSV = "\"" + AgendaExportHead.join("\";\"").concat("\"\n");
    newExport.forEach(line => {
      exportCSV = exportCSV.concat(`"${line.Betrag.toString().replace(".",",")}";"";`);
      exportCSV = exportCSV.concat(`"${Gegenkonto}";"";`);
      exportCSV = exportCSV.concat(`"${line.Datum}";`);
      exportCSV = exportCSV.concat(`"${Konto}";`);
      exportCSV = exportCSV.concat(`"${line.Nachname}";"";"";"";""\n`);
    });
    
    const filename = "AgendaImport.csv"
    
    const download = document.createElement('a');
    download.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(exportCSV));
    download.setAttribute('download', filename);
    
    download.click();
    
  };


  return(
  <div className = "page page--center">
    <div>
      <h2>Hier den Novalnet-Export hochladen!</h2> 
    </div>
    <FileUploader
      onFileUpload={convertData}
      buttonSize="big"
     />
  </div>
  )
}

export default NovalnetToAgenda;