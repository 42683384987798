import React from 'react';
import FileUploader from '../components/FileUploader';
import { AgendaExportHead2 } from '../types/agenda';
import '../App.scss'


const ShopifyToAgenda: React.FC = () => {

  const convertData = (file: string) => {
    const lines = file.split("\n");

    const newExport:Array<any> = [];
    if(lines[0].split(",")[5] !== "Payout Status") {
      alert("Unerwartete Datei, oder mit der Datei stimmt etwas nicht!");
      return;
    }
    
    lines.shift();
    lines.forEach(line => {
      const newLine = line.split(",");
      console.log(newLine);
      if (newLine.length > 1) {
          newExport.push({
            betrag: -parseFloat(newLine[8]),
            datum: newLine[0].split(" ")[0],
            zahlungsart: newLine[12] + " " + [5],
            status: newLine[5],
            orderNumber: newLine[2]
          });
      }
    })

    //Export data
    const Gegenkonto = 1260;
    const Konto = 10000;
    
    let exportCSV = "\"" + AgendaExportHead2.join("\";\"").concat("\"\n");
    newExport.forEach(line => {
      exportCSV = exportCSV.concat(`"${line.betrag.toString().replace(".",",")}";"";`);
      exportCSV = exportCSV.concat(`"${Gegenkonto}";"";`);
      exportCSV = exportCSV.concat(`"${line.status !== "paid" ? line.status : ""}";`);
      exportCSV = exportCSV.concat(`"${line.datum}";`);
      exportCSV = exportCSV.concat(`"${Konto}";`);
      exportCSV = exportCSV.concat(`"${line.orderNumber}";"";"";"";""\n`);
    });
    
    const filename = "AgendaImport.csv"
    
    const download = document.createElement('a');
    download.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(exportCSV));
    download.setAttribute('download', filename);
    
    download.click();
    
  };


  return(
  <div className = "page page--center">
    <div>
      <h2>Hier den Shopify Payment Transaction Export hochladen.</h2> 
    </div>
    <FileUploader
      onFileUpload={convertData}
      buttonSize="big"
     />
  </div>
  )
}

export default ShopifyToAgenda;