import { PDFDocument, PDFEmbeddedPage, PDFPage, PDFFont, PDFPageDrawTextOptions, StandardFonts, layoutMultilineText, TextAlignment, rgb } from 'pdf-lib';
import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileUploader from '../components/FileUploader';
import i18n from '../i18n';
import { Article, DefaultInvoiceData } from '../types/invoice';
import '../App.scss';
import './css/InvoiceGenerator.scss';
import { Trash } from '../icons/IonIcons'
import { useEffect } from 'react';

//Here you can change the style, position and spacing of the PDF file
const leftXPosition = 57;
const PDFStyles = {
  defaultPageSettings: {
    fontSize: 11,
    lineHeight: 14
  },
  address: { x: leftXPosition, y: 705 },
  deliveryAddress: {
    address: {x: 260, y:691},
    head: {x: 260, y: 705}
  },
  pageNumbers: { x: leftXPosition, y: 60 },
  head: {
    caption: { x: leftXPosition, y: 585, size: 13 },
    dataField: {
      default: { x: leftXPosition, y: 560, lineHeight: 14 },
      xOffsetData: 130,
      xOffsetColumn: 240,
    }
  },
  table: {
    start: {
      x: 80,
      y: {
        firstPage: 495,
        additionalPage: 730
      },
      moveDown: 25,
    },
    isRightAligned: [1, 0, 0, 1, 1, 1, 1],
    moveRight: [5, 50, 210, 30, 90, 85, 0],
    moveDown: 20
  }
}

const EmptyArticle: Article = {
  articleNumber: "",
  description: "",
  quantity: 0,
  unit: "pcs",
  germanPrice: 0,
  netPrice: 0
}

const InvoiceGenerator: React.FC = () => {

  const {t} = useTranslation('common');

  const [pdfData, setPdfData] = useState<string>();
  const [invoiceData, setInvoiceData] = useState(DefaultInvoiceData);
  const [article, setArticle] = useState<Article>(EmptyArticle);
  const [livePdfPreview, setLivePdfPreview] = useState(true);
  const [vatRates, setVatRates] = useState<Object>({});
  const [selectedArticle, setSelectedArticle] = useState<number | undefined>(undefined);
  const [deliveryAddressActive, setDeliveryAddressActive] = useState(false);


  const createNewPage = (document: PDFDocument, font: PDFFont) => {
    let page = document.addPage();
    page.setFontSize(PDFStyles.defaultPageSettings.fontSize);
    page.setLineHeight(PDFStyles.defaultPageSettings.lineHeight);
    page.setFont(font);
    return page;
  }

  const drawFirstTableRow = (page: PDFPage, boldFont: PDFFont, isFirstPage: boolean = false) => {
    const columns = [
      t('invoice.table.position'),
      t('invoice.table.articleNumber'),
      t('invoice.table.description'),
      t('invoice.table.quantity'),
      t('invoice.table.unit'),
      t('invoice.table.rate'),
      t('invoice.table.amount')];
    page.moveTo(PDFStyles.table.start.x, isFirstPage ? PDFStyles.table.start.y.firstPage : PDFStyles.table.start.y.additionalPage);
    for (let i in columns) {
      if (PDFStyles.table.isRightAligned[i]) {
        drawTableTextRightAlligned(columns[i], page, { font: boldFont });
      } else {
        page.drawText(columns[i], { font: boldFont });
      }
      page.moveRight(PDFStyles.table.moveRight[i]);
    }
    //Move Cursor to beginning of next line
    page.moveDown(PDFStyles.table.start.moveDown);
    page.moveLeft(PDFStyles.table.moveRight.reduce((a, b) => a + b));
  }

  const drawInvoiceItems = (document: PDFDocument, standardFont: PDFFont, boldFont: PDFFont, template: PDFEmbeddedPage) => {
    let page = document.getPage(0);
    invoiceData.articles.forEach((article, index) => {
      //TODO Check for new page
      if(page.getPosition().y < 200) {
        page = addPage(document, standardFont, boldFont, template);
      }
      let descriptionLines = 0;

      drawTableTextRightAlligned((index+1).toString(), page, {font: standardFont});
      page.moveRight(PDFStyles.table.moveRight[0]);
      page.drawText(article.articleNumber? article.articleNumber : "---");
      page.moveRight(PDFStyles.table.moveRight[1]);
      const formattedText = layoutMultilineText(article.description ? article.description : "---", {alignment: TextAlignment.Left, font: standardFont, fontSize: PDFStyles.defaultPageSettings.fontSize, bounds: {x: 0,  y: 0, width: 180, height: 100}})
      formattedText.lines.forEach(line => {
        page.drawText(line.text);
        page.moveDown(PDFStyles.defaultPageSettings.lineHeight);
        descriptionLines ++;
      })
      page.moveUp((descriptionLines * PDFStyles.defaultPageSettings.lineHeight));
      page.moveRight(PDFStyles.table.moveRight[2]);
      drawTableTextRightAlligned(article.quantity? article.quantity.toString() : "", page, {font: standardFont});
      page.moveRight(PDFStyles.table.moveRight[3]);
      drawTableTextRightAlligned(article.unit? article.unit=== "pcs" ? t('invoice.table.pcs') : article.unit : "-", page, {font: standardFont});
      page.moveRight(PDFStyles.table.moveRight[4]);
      let price = invoiceData.priceType === "net" ? article.price! : article.germanPrice!;
      price = invoiceData.type === "invoice" ? price : -price;
      drawTableTextRightAlligned(price.toFixed(2).toString().replace(".",","), page, {font: standardFont});
      page.moveRight(PDFStyles.table.moveRight[5]);
      drawTableTextRightAlligned((article.quantity!*price).toFixed(2).toString().replace(".",","), page, {font: standardFont});

      //Move Cursor to beginning of next line
      page.moveDown(PDFStyles.table.moveDown + ((descriptionLines-1) * PDFStyles.defaultPageSettings.lineHeight));
      page.moveLeft(PDFStyles.table.moveRight.reduce((a, b) => a + b));
    })
  }
  
  const drawTotal = (page: PDFPage, standardFont: PDFFont, boldFont: PDFFont) => {
    page.moveRight(PDFStyles.table.moveRight.reduce((a,b,i) => i < 4 ? a+b : a));
    const moveX = PDFStyles.table.moveRight[4]+PDFStyles.table.moveRight[5];
    page.drawText(t('invoice.table.subTotal'));
    page.moveRight(moveX);
    let total = invoiceData.articles.reduce((a:number,b) => {return (a+(invoiceData.priceType==="net" ? b.price! : b.germanPrice!)*b.quantity!)},0);
    total = invoiceData.type === "invoice" ? total : -total
    const  noTax = Math.round(total/(invoiceData.VAT/100+1)*100)/100;
    drawTableTextRightAlligned(noTax.toFixed(2).replace(".", ","), page, {font: standardFont});
    page.moveDown(PDFStyles.defaultPageSettings.lineHeight);
    page.moveLeft(moveX);
    page.drawText(t('invoice.table.VAT',{VAT: invoiceData.VAT}));
    page.moveRight(moveX);
    drawTableTextRightAlligned((Math.round((total - noTax)*100)/100).toFixed(2).replace(".", ","), page, {font: standardFont});
    page.moveDown(PDFStyles.table.moveDown);
    page.moveLeft(moveX);
    page.drawText(t('invoice.table.total'), {font: boldFont});
    page.moveRight(moveX);
    drawTableTextRightAlligned(total.toFixed(2).toString().replace(".",","), page, {font: boldFont});
    
    //Move Cursor to beginning of next line
    page.moveDown(PDFStyles.table.start.moveDown);
    page.moveLeft(PDFStyles.table.moveRight.reduce((a, b) => a + b));
  }

  const drawFooter = (page: PDFPage) => {
    page.drawText(invoiceData.customFooter? invoiceData.customFooter : 
      [t('invoice.footer.shipping',{shippingMethod: t(`invoice.footer.shippingMethods.${invoiceData.footer.shippingMethod}`)}),
      t('invoice.footer.payment', {paymentMethod: t(`invoice.footer.paymentMethods.${invoiceData.footer.paymentMethod}`)}),
      t('invoice.footer.delivery')].join("\n"));
  }

  const drawTableTextRightAlligned = (text: string, page: PDFPage, options: PDFPageDrawTextOptions) => {
    if (!options.font) {
      console.log("Text was not drawn! - You need to pass font in DrawTextOptions to align text right.");
      return;
    }
    const width = options.font.widthOfTextAtSize(text, PDFStyles.defaultPageSettings.fontSize);
    page.moveLeft(width);
    page.drawText(text, options);
    page.moveRight(width);
  }

  const addPage = (document:PDFDocument, standardFont: PDFFont, boldFont: PDFFont, template: PDFEmbeddedPage) => {
    //add secons page
    const page = createNewPage(document, standardFont);
    page.drawPage(template);
    drawFirstTableRow(page, boldFont);

    return page;
  }

  const createInvoice = async () => {
    const invoicePDF = await PDFDocument.create();
    invoicePDF.setAuthor("ICLETTA GmbH");
    const standardFont = invoicePDF.embedStandardFont(StandardFonts.Helvetica);
    const boldFont = invoicePDF.embedStandardFont(StandardFonts.HelveticaBold);

    //Get invoice templates
    let invoiceTemplates: PDFEmbeddedPage[] = [];
    invoiceTemplates[0] = (await invoicePDF.embedPdf(await fetch("templates/invoice/page1.pdf").then(resolve => resolve.arrayBuffer())))[0];
    invoiceTemplates[1] = (await invoicePDF.embedPdf(await fetch("templates/invoice/page2.pdf").then(resolve => resolve.arrayBuffer())))[0];

    let page = createNewPage(invoicePDF, standardFont);
    page.drawPage(invoiceTemplates[0]);

    //Draw address
    invoiceData.address ?
    page.drawText(invoiceData.address, PDFStyles.address) :
    page.drawText("Bitte Adresse eingeben!", {...PDFStyles.address, color: rgb(1,0,0)});

    if (invoiceData.deliveryAddress && deliveryAddressActive) {
      page.drawText(t('invoice.head.deliveryAddress'), {...PDFStyles.deliveryAddress.head, font: boldFont});
      page.drawText(invoiceData.deliveryAddress, PDFStyles.deliveryAddress.address);
    }

    //Draw head of invoice
    page.drawText(invoiceData.customTitle ? invoiceData.customTitle : invoiceData.type === "invoice" ? t('invoice.head.title'): t('invoice.head.titleCorrection'), { ...PDFStyles.head.caption, font: boldFont });
    page.drawText([t('invoice.head.invoiceNumber'), t('invoice.head.orderNumber'), t('invoice.head.orderDate')].join("\n"), PDFStyles.head.dataField.default);
    page.drawText([t('invoice.head.date'), t('invoice.head.customerId'), t('invoice.head.deliveryDate')].join("\n"), { ...PDFStyles.head.dataField.default, x: PDFStyles.head.dataField.default.x! + PDFStyles.head.dataField.xOffsetColumn });
    //Draw data of head
    page.drawText([invoiceData.invoiceNumber, invoiceData.orderNumber ? invoiceData.orderNumber : "---", invoiceData.orderDate].join("\n"), { ...PDFStyles.head.dataField.default, x: PDFStyles.head.dataField.default.x! + PDFStyles.head.dataField.xOffsetData })
    page.drawText([invoiceData.date, invoiceData.customerId ? invoiceData.customerId : "---", invoiceData.deliveryDate].join("\n"), { ...PDFStyles.head.dataField.default, x: PDFStyles.head.dataField.default.x! + PDFStyles.head.dataField.xOffsetData + PDFStyles.head.dataField.xOffsetColumn })

    drawFirstTableRow(page, boldFont, true);
    drawInvoiceItems(invoicePDF, standardFont, boldFont, invoiceTemplates[1]);
    page = invoicePDF.getPage(invoicePDF.getPageCount()-1);
    drawTotal(page, standardFont, boldFont);
    drawFooter(page);

    //Draw page numbers 
    const ammountPages = invoicePDF.getPageCount();
    for (let pageCount = 1; pageCount <= ammountPages; pageCount++) {
      const page = invoicePDF.getPage(pageCount - 1);
      page.drawText(t('invoice.page') +  ` ${pageCount}/${ammountPages}`, PDFStyles.pageNumbers);
    }

    //finalize PDF
    const localPdfBytes = await invoicePDF.save();
    //Prepare in Browser preview
    const blob = new Blob([localPdfBytes], { type: "application/pdf" });
    const invoiceUrl = URL.createObjectURL(blob);
    setPdfData(invoiceUrl);

    //download(pdfBytes, "Rechnung: 20202123.pdf", "application/pdf");


  }

  const addArticle = (thisArticle: Article, index?: number) => {
    if (index) {
      let newArticles = [...invoiceData.articles];
      newArticles[index] = article;
      setInvoiceData(old => { return { ...old, articles: newArticles}});
      setSelectedArticle(undefined);
    } else {
      let newArticle = {...thisArticle};
      newArticle.netPrice = newArticle.netPrice ? newArticle.netPrice : newArticle.germanPrice!/1.19;
      newArticle.price = Math.round((newArticle.netPrice * (1 + invoiceData.VAT/100))*100)/100;
      setInvoiceData(old => { return { ...old, articles: old.articles.concat(newArticle)}});
    }
    setArticle(EmptyArticle);
}

const handleVATChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  setInvoiceData(old => {
    return { ...old, VAT: e.target.valueAsNumber }
  });
  const newArticles = invoiceData.articles.map(article => {
    article.price = Math.round((article.netPrice! * (1 + e.target.valueAsNumber/100))*100)/100;
    return article;
  });
  setInvoiceData(old => {
    return {...old, articles: newArticles}
  })
}

const importFile = (file:string) => {
  let linesCols = file.split("\n").map(line =>  {
    return line.split("\t");
  })
  let identifier = linesCols.map(line => line[0]);
  let updatedInvoiceData = {...invoiceData};
  updatedInvoiceData.date = linesCols[0][1];
  updatedInvoiceData.deliveryDate = linesCols[0][1];
  updatedInvoiceData.orderDate = "";
  updatedInvoiceData.invoiceNumber = linesCols[1][1];
  updatedInvoiceData.orderNumber = linesCols[2][1];
  setInvoiceData(updatedInvoiceData);

  for (let i = identifier.findIndex(id => id === "1"); i < identifier.findIndex(id => id === "Nettobetrag in Euro"); i++) {
    const line = [...linesCols[i]];
    addArticle({
      articleNumber: line[1],
      description: line[2],
      quantity: parseInt(line[3]),
      unit: "pcs",
      germanPrice: parseFloat(line[4].replace(",","."))
    })
  }
}

useEffect(() => {
  //get VAT rates
  fetch('configs/vatRates.json').then(res => {
    res.json().then(data => {
      setVatRates(data);
    });
  })
}, []);

useEffect(() => {
  if (livePdfPreview) {
    createInvoice();
  }
  //eslint-disable-next-line
},[invoiceData, livePdfPreview]);

//auto-detect country 
useEffect(() => {
  if (!vatRates) return;
  const splitAdress = invoiceData.address.split("\n");
  const possibleCountry = splitAdress[splitAdress.length-1].trim();
  const countryCodes = Object.keys(vatRates);
  countryCodes.forEach(countryCode => {
    if ((vatRates as any)[countryCode].countryName === possibleCountry) {
      setInvoiceData(oldData => {return{...oldData, country: countryCode, VAT: (vatRates as any)[countryCode].rate}});
    }
  });
  //eslint-disable-next-line
}, [invoiceData.address]);

const selectArticle = (index?: number, article?: Article) => {
  if (!index || !article || selectedArticle === index) {
    setSelectedArticle(undefined);
    setArticle(EmptyArticle);
  } else {
    setSelectedArticle(index);
    setArticle(article);
  }
}

  return (
    <div className="page" >
        <h2>Rechnung konfigurieren</h2>
        <FileUploader onFileUpload={importFile} encoding= "iso88591"/>
      <div className="page__row">
        <div className="page__column page__column--left">
          <h3>Rechnungseinstellungen</h3>
          <div className="page__seperator" />
          <h5>Überschrift</h5>
          <label className="page__column--small-text"><input type="checkbox" checked={invoiceData.customTitle ? false : (invoiceData.type === "invoice")} onChange={e => setInvoiceData(oldData => { return { ...oldData, type: e.target.checked ? "invoice" : "correction", customTitle: "" }})} />Rechnung</label>
          <label className="page__column--small-text"><input type="checkbox" checked={invoiceData.customTitle ? false : (invoiceData.type === "correction")} onChange={e => setInvoiceData(oldData => { return { ...oldData, type: e.target.checked ? "correction" : "invoice", customTitle: "" }})} />Rechnungskorrektur</label>
          <label className="page__column--small-text">Eigene:<input type="text" className="ig__text-input ig__text-input--long" value={invoiceData.customTitle} onChange={e => setInvoiceData(oldData => {return{...oldData, customTitle: e.target.value}})}></input></label>
          <div className="page__seperator" />
          <label className="page__column--small-text">Sprache der Rechnung:
            <select id="language" value={i18n.language} onChange={(e) => i18n.changeLanguage(e.target.value)} >
              {Object.keys(i18n.services.resourceStore.data).map(language => <option key={language} value={language}>{t(`languages.${language}`)}</option>)}
            </select>
          </label>
          <label className={(vatRates && (vatRates as any)[invoiceData.country] && (vatRates as any)[invoiceData.country].rate === invoiceData.VAT) ? "page__column--small-text" : "page__column--small-text ig__grey"}>Land Ust. :
            <select id="country" value={invoiceData.country} onChange={e => setInvoiceData(oldData => {return{...oldData, country: e.target.value, VAT: (vatRates as any)[e.target.value].rate}})}>
              {vatRates ? Object.keys(vatRates).map((countryCode, index) => {
                return (
                  <option key={countryCode} value={countryCode}>{(vatRates as any)[countryCode].countryName}</option>
                  )
              }) : null}
            </select>
          </label>
          <label className="page__column--small-text">Umsatzsteuer in %
            <input type="number" className="ig__text-input ig__text-input--small" value={invoiceData.VAT} onChange={handleVATChange} />
          </label>
          <label className="page__column--small-text">Preis festsetzen:
          <select id="priceType" value={invoiceData.priceType} onChange={e => setInvoiceData(oldData => {return{...oldData, priceType: e.target.value as "net" | "gross"}})}>
            <option value="net" >Netto</option>
            <option value="gross" >Brutto</option>
          </select>
          </label>
          <div className="page__seperator" />
          <label className="page__column--small-text">Versandart:
          <select id="shippingMethod" value={invoiceData.footer.shippingMethod} onChange={e => setInvoiceData(oldData => {return{...oldData, footer: {...oldData.footer, shippingMethod: e.target.value as any}}})} >
            <option value="forwarder" >Spedition</option>
            <option value="parcel" >Paket</option>
            <option value="letter" >Brief</option>
            <option value="pickup" >Selbstabholung</option>
          </select>
          </label>
          <label className="page__column--small-text" >Zahlungsart:
          <select id="paymentMethods" value={invoiceData.footer.paymentMethod} onChange={e => setInvoiceData(oldData => {return{...oldData, footer: {...oldData.footer, paymentMethod: e.target.value as "payPal" | "creditCard" | "bankTransfer"}}})} >
            <option value="payPal" >PayPal</option>
            <option value="creditCard" >Kreditkarte</option>
            <option value="bankTransfer" >Vorkasse</option>
          </select>
          </label>
        </div>

        <div className="page__column">
          <h3>Rechnungsdaten</h3>
          <div className="page__seperator" />
          <div>
            <div className="page__row page__column--left-aligned" >
            <label className="page__column--small-text"><input type="checkbox" checked={deliveryAddressActive} onChange={e => setDeliveryAddressActive(e.target.checked)} />Lieferadresse</label>
            </div>
            <h5>Addresse</h5>
            <textarea id="address" className="ig__address-box" value={invoiceData.address} onChange={e => setInvoiceData(oldData => { return { ...oldData, address: e.target.value } })} />
            {deliveryAddressActive ?
            <React.Fragment>
              <h5>Lieferadresse</h5>
              <textarea id="deliveryAddress" className="ig__address-box" value={invoiceData.deliveryAddress} onChange={e => setInvoiceData(oldData => { return { ...oldData, deliveryAddress: e.target.value } })} />
            </React.Fragment> 
            : null}
          </div>
          <div className="page__row">
            <div className="page__column page__column--small-sub-col" >
              <div>Bestellung vom </div>
              <input type="text" className="ig__text-input" value={invoiceData.orderDate} onChange={e => setInvoiceData(oldData => { return { ...oldData, orderDate: e.target.value } })} />
              <div>Lieferdatum</div>
              <input type="text" className="ig__text-input" value={invoiceData.deliveryDate} onChange={e => setInvoiceData(oldData => { return { ...oldData, deliveryDate: e.target.value } })} />
              <div>Kundennumer</div>
              <input type="text" className="ig__text-input" value={invoiceData.customerId} onChange={e => setInvoiceData(oldData => { return { ...oldData, customerId: e.target.value } })} />
            </div>
            <div className="page__column page__column--small-sub-col" >
              <div>Rechnungsnummer</div>
              <input type="text" className="ig__text-input" value={invoiceData.invoiceNumber} onChange={e => setInvoiceData(oldData => { return { ...oldData, invoiceNumber: e.target.value } })} />
              <div>Datum</div>
              <input type="text" className="ig__text-input" value={invoiceData.date} onChange={e => setInvoiceData(oldData => { return { ...oldData, date: e.target.value } })} />
              <div>Auftragsnummer</div>
              <input type="text" className="ig__text-input" value={invoiceData.orderNumber} onChange={e => setInvoiceData(oldData => { return { ...oldData, orderNumber: e.target.value } })} />
            </div>
          </div>
        </div>
        <div className="page__column" >
          <h3>Artikel verwalten</h3>
          <div className="page__seperator" />
          <h5>Artikel hinzufügen</h5>
          <div className="page__row" >
            <div className="page__column page__column--small-sub-col">
              <div>Art.Nr.</div>
              <input type="text" className="ig__text-input" value={article.articleNumber} onChange={e => setArticle(oldData => { return { ...oldData, articleNumber: e.target.value } })} />
              <div>Beschreibung</div>
              <input type="text" className="ig__text-input" value={article.description} onChange={e => setArticle(oldData => { return { ...oldData, description: e.target.value } })} />
              <div>Menge</div>
              <input type="number" className="ig__text-input" value={article.quantity} onChange={e => setArticle(oldData => { return { ...oldData, quantity: e.target.valueAsNumber } })} />
            </div>
            <div className="page__column page__column--small-sub-col">
              <div>Einheit</div>
              <input type="text" className="ig__text-input" value={article.unit} onChange={e => setArticle(oldData => { return { ...oldData, unit: e.target.value } })} />
              <div>Bruttopreis</div>
              <input type="number" className="ig__text-input" value={article.germanPrice} onChange={e => setArticle(oldData => { return { ...oldData, germanPrice: e.target.valueAsNumber, netPrice: e.target.valueAsNumber/1.19 } })} />
              <div>Nettopreis</div>
              <input type="number" className="ig__text-input" value={article.netPrice} onChange={e => setArticle(oldData => { return { ...oldData, netPrice: e.target.valueAsNumber, germanPrice: e.target.valueAsNumber*1.19 } })} />
            </div>
          </div>
          <div className="button page--small-text" onClick={() => addArticle(article, selectedArticle)}>{selectedArticle ? "Speichern" : "Hinzufügen"}</div>
          <div className="page__column page__column--preview">
            <h5>Vorschau Artikel</h5>
            <table className="ig__preview-table">
              <tbody>
                <tr>
                  <th>Pos.</th>
                  <th>Art.Nr.</th>
                  <th>Beschreibung</th>
                  <th>Menge</th>
                  <th>Preis</th>
                </tr>
                {invoiceData.articles.map((article, index) => {
                  return (
                    <tr 
                      key={index + (article.articleNumber ? article.articleNumber : "-")}
                      className={selectedArticle === index ? "ig__preview-table__selected": ""} 
                      onClick={() => selectArticle(index, article)}
                    >
                      <th>{index + 1}</th>
                      <th>{article.articleNumber}</th>
                      <th>{article.description}</th>
                      <th>{article.quantity}</th>
                      <th>{(invoiceData.priceType === "net" ? article.price! : article.germanPrice!).toFixed(2)}</th>
                      <th onClick={() => {
                      let newArticles = [...invoiceData.articles];
                      newArticles.splice(index,1);
                      setInvoiceData(old => {return{...old, articles: newArticles}})
                      }}><Trash /> </th>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="page__column page__column--big">
        <h3>Rechnungsvorschau</h3>
        <div className="page__seperator" />
        <div className="page__row page__row--center">
          <label className="page__column--small-text"><input type="checkbox" checked={livePdfPreview} onChange={e => setLivePdfPreview(e.target.checked)} />Live PDF Vorschau</label>
          <div className="button" onClick={() => createInvoice()} >PDF erzeugen!</div>
          <a className="button" href={pdfData} download={`Rechnung_${invoiceData.orderNumber}`}>PDF herunterladen!</a>
        </div>
        <embed width="100%" height="800px" title="pdf" src={pdfData + "#toolbar=0"} style={{ border: "grey solid 1px" }} />
      </div>
    </div>
  )
}

export default InvoiceGenerator;