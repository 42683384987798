export interface AgendaExport {

}

export const AgendaExportHead = [
    "UMSATZ IN EURO",
    "STEUERSCHLÜSSEL",
    "GEGENKONTO",
    "BELEG1",
    "DATUM",
    "KONTO",
    "BUCHUNGSTEXT",
    "UMSATZSTEUER-ID",
    "USt-Land Ursprung",
    "USt-Land Bestimmung",
    "USt-% Bestimmung"
]

export const AgendaExportHead2 = [
    "UMSATZ IN EURO",
    "STEUERSCHLÜSSEL",
    "GEGENKONTO",
    "BELEG1",
    "BELEG2",
    "DATUM",
    "KONTO",
    "BUCHUNGSTEXT",
    "UMSATZSTEUER-ID",
    "USt-Land Ursprung",
    "USt-Land Bestimmung",
    "USt-% Bestimmung"
]