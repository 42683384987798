import Holidays, { HolidaysTypes } from "date-holidays";

/**
 * Returns the working days in the given month as string. (DD) - If requestedDays is provided, only working days are returned, that match the requested days.
 * @param requestedDays Format: Sunday - Staurday --> 0 - 6
 */
 export const getWorkingDaysOfMonth = (year: number, UTCmonth: number, requestedDays: Array<number> | undefined = undefined) => {
    let workingDays: Array<{date: string, week: number}> = [];
    let dates = getWorkingDayObjectsOfMonth(year, UTCmonth);

    if (requestedDays && requestedDays.length > 0) {
        //Filter all dates to match the requested Days
        dates = dates.filter(dateObj => 
            !!requestedDays.find(day => day === dateObj.date.getUTCDay())
        )
    }

    //Export as numbers
    dates.forEach(dateObj => {
        const newEntry = {
            date: dateObj.date.getUTCDate().toString().padStart(2, "0"),
            week: dateObj.week
        }
        workingDays.push(newEntry);
    })

    return workingDays;
 }

 export const getHolidaysOfMonth = (year: number, UTCmonth: number) => {
    let requestedDays: Array<{date: string, week: number, name: string}> = [];
    let dates = getDayObjectsOfMonth(year, UTCmonth);
    const holidays = new Holidays('DE', 'HE'); //Get holidays in Hessen, Germany
    //Filter weekend and return remaining holidays
    const filteredDates = dates.filter(dateObj => 
        dateObj.date.getUTCDay() > 0 && dateObj.date.getUTCDay() < 6 && holidays.isHoliday(dateObj.date) && (holidays.isHoliday(dateObj.date) as HolidaysTypes.Holiday[])[0].type === "public"
    );
    filteredDates.forEach(dateObj => {
        requestedDays.push({
            date: dateObj.date.getUTCDate().toString().padStart(2, "0"),
            week: dateObj.week,
            name: (holidays.isHoliday(dateObj.date) as HolidaysTypes.Holiday[])[0].name
        })
    });
    
    return requestedDays;
 }

 const getWorkingDayObjectsOfMonth = (year: number, UTCmonth: number) => {
    let dates = getDayObjectsOfMonth(year, UTCmonth);
    const holidays = new Holidays('DE', 'HE'); //Get holidays in Hessen, Germany

    //Filter weekend and holidays
    const filteredDates = dates.filter(dateObj => 
        dateObj.date.getUTCDay() > 0 && dateObj.date.getUTCDay() < 6 && !(holidays.isHoliday(dateObj.date) && (holidays.isHoliday(dateObj.date) as HolidaysTypes.Holiday[])[0]?.type === "public")
    );

    return filteredDates;
 }

 const getDayObjectsOfMonth = (year: number, UTCmonth: number) => {
    let workingDays: Array<{date: Date, week: number}> = [];

    //Get all days in given month
    let thisDate = new Date(Date.UTC(year, UTCmonth, 1));
    while (thisDate.getUTCMonth() === UTCmonth) {
        workingDays.push({date: new Date(thisDate), week: getISO8601_week_no(thisDate)});
        thisDate.setUTCDate(thisDate.getUTCDate() + 1);
    }

    return workingDays;
 }

 export const getISO8601_week_no = (dt: Date) => {
    var tdt = new Date(dt.valueOf());
    var dayn = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    var firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
     tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }
    return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
}