import React, { useEffect, useState } from 'react';
import { AppValues } from '../assets/constants'
import Login from '../pages/Login';
import bcrypt from 'bcryptjs'

interface Props {
  component: React.ComponentType,

}

//This is not secure!

const RequireLogin: React.FC<Props> = ({component: RouteComponent}) => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const savedLogin = sessionStorage.getItem("login") || "";
    bcrypt.compare(savedLogin, AppValues.loginPhrase).then(result => {
      setIsAuthenticated(result);
    })
  });

    if (isAuthenticated) {
      return <RouteComponent />
    }
    return <Login />
}

export default RequireLogin;