export interface TimeTrackingSettings {
    monthlyTarget: number, // In Hours
    dailyTarget: number, // In Hours
    isMinijob: boolean,
    forceTarget: boolean,
    requestedDays?: Array<number>,
    mandatoryDates?: Array<string>,
    sickDates?: Array<string>,
    holidayDates?: Array<string>,
}

export const TimeTrackingDefaultSettings: TimeTrackingSettings = {
    monthlyTarget: 37.5,
    dailyTarget: 4,
    isMinijob: true,
    forceTarget: false,
}

export const TimeTrackingRules = {
    workingTime: {
        perWeek: {
            max: 1200 // in minutes
        },
        daily: {
            average: 360, // in minutes
            maxVariation: 120, // +- in minutes
            maxVariationNoMinijob: 60 // +- in minutes
        },
        perMonth: {
            maxVariation: 10, // +- percentage of target
        }
    },
    break: {
        workingTimeRequiresBreak: 360, // in minutes
        minBreakTime: 30, // in minutes
        maxBreakTimeVariation: 30, // just + in minutes
        minutesUntilBreakStart: 246,
    },
    startOfWork: {
        averageTime: {
            hour: 9,
            minute: 0
        },
        maxVariationOfTime: 30 // +- in minutes
    }
}

export const TimeTrackingExportFileHead = [
    "DATUM_VON",
    "DATUM_BIS",
    "ZEIT_VON",
    "ZEIT_BIS",
    "STUNDENLOHN",
    "NOTIZ"
]