import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import common_en from "./translations/en/common.json"
import common_de from "./translations/de/common.json";

i18n
    .use(initReactI18next)
    .init({
        lng: 'de',
        fallbackLng: 'en',
        ns: ["common"],
        defaultNS: "common",
        interpolation: {escapeValue: false}, //React already does escaping
        resources: {
            en: {common: common_en},
            de: {common: common_de},
        },
    });

    export default i18n;